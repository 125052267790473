import React from "react"

import "./ogado-book.scss"

const OgadoBook = ({ lang, book }) => (
  <div className="ogado__book">
    <div className="ogado__book-header">
      <h3 className="ogado__book-header-title">{book.title}</h3>
      <span className="ogado__book-header-subtitle">
        <span className="ogado__book-header-subtitle__added">
          {lang === "it" ? "Aggiunto" : "Added"} {book.timesAdded}{" "}
          {book.timesAdded === 1
            ? lang === "it"
              ? "volta"
              : "time"
            : lang === "it"
            ? "volte"
            : "times"}
        </span>
      </span>
    </div>

    <div className="row content__content-gallery">
      <div
        style={{
          backgroundImage: `url('${book.image_url.replace(/_.*_./, "")}')`,
        }}
        className="content__content-image"
      >
        <div className="content__content-image-description">
          <span className="genre">
            <strong>{book.format}</strong>
          </span>
        </div>
      </div>
      <div className="ogado__book-info">
        <div className="ogado__book-info_author">
          <em>{lang === "it" ? "Autore" : "Author"}</em>
          <strong>{book.authors[0]?.name}</strong>
        </div>
        <hr />
        <div className="ogado__book-info_author">
          <em>{book.format}</em>
          <span>
            {lang === "it" ? "Uscita" : "Published in"}{" "}
            <strong>{book.publication_year}</strong>
          </span>
        </div>
      </div>
    </div>

    <span style={{ paddingLeft: "20px" }}>
      <strong>{lang === "it" ? "Disponibile su" : "Available on"}</strong>
    </span>

    <div className="content__platforms-list" style={{ overflowX: "auto" }}>
      {book?.buy_links
        ?.filter(link => link.logo !== null)
        .map(platform => (
          <a
            href={platform.link}
            target="_blank"
            rel="noopener noreferrer"
            className="content__platforms-platform"
          >
            <div
              style={{
                backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/oogado-1711e.appspot.com/o/assets%2Famazon_logo.png?alt=media&token=2e715795-3606-4d7b-9525-630e69a84849')`,
              }}
            ></div>
          </a>
        ))}
    </div>

    <span style={{ marginTop: "2rem", paddingLeft: "10px" }}>
      <strong>{lang === "it" ? "Trama" : "Description"}</strong>
    </span>
    <p
      className="content__content-overview"
      dangerouslySetInnerHTML={{ __html: book.description }}
    ></p>
  </div>
)

export default OgadoBook
