import React from "react"
import firebase from "gatsby-plugin-firebase"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/content/content.scss"
import Header from "../../components/header/header"
import OgadoBook from "../../components/ogado-book/ogado-book"

export const EnglishBookTemplate = ({ data }) => {
  if (!!firebase.analytics && !!firebase.performance) {
    firebase.analytics()
    firebase.performance()
  }

  const { book } = data

  return (
    <Layout>
      <SEO
        title={book.title}
        image={book.image_url?.replace(/_.*_./, "")}
        description={`Add ${book.title} on yuor Ogado list and never miss a new`}
        lang="en"
        keywords={[
          "ogado",
          "entertainment",
          "digital",
          "book",
          book.title,
          ...book.buy_links.map(platform => platform.name),
          ...book.authors.map(author => author.name),
        ]}
      />
      <Header lang="en" />

      <OgadoBook lang="en" book={book} />
    </Layout>
  )
}

export default EnglishBookTemplate

export const pageQuery = graphql`
  query EnglishBookById($id: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    book(id: { eq: $id }) {
      authors {
        name
      }
      buy_links {
        name
        link
        logo
      }
      description
      image_url
      publication_year
      timesAdded
      title
      format
    }
  }
`
